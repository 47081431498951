export const INPUT_COMMON_PROPS = {
  isRequired: true,
  size: 'sm',
  type: 'text',
  variant: 'outline',
  colorScheme: 'blackAlpha',
  backgroundColor: 'bgPrimary',
  color: 'mainColorText',
  fontSize: 'sm',
  borderRadius: '0',
  fontFamily: 'body',
  borderColor: 'transparent',
  placeholder: '...',
  _placeholder: { color: 'blackAlpha.500' },
  _focus: { borderColor: 'alternativeColorText' },
};

export const SELECT_COMMON_PROPS = {
  borderRadius: '0',
  size: 'sm',
  variant: 'outline',
  backgroundColor: 'bgPrimary',
  color: 'mainColorText',
  fontFamily: 'body',
  borderColor: 'transparent',
  _focus: { borderColor: 'transparent' },
};

export const FORM_LABEL_PROPS = {
  fontSize: 'sm',
  color: 'alternativeColorText',
  margin: '14px 0 8px 0',
  fontFamily: 'body',
};

export const ERROR_PROPS = {
  color: 'red',
  marginTop: '4px',
};

export const TYPE = {
  YES: 'YA',
  NO: 'TIDAK',
};

export const ERROR_TYPE = {
  name: undefined,
  email: undefined,
  phone: undefined,
  address: undefined,
};
